<template>
  <div class="miya-lang" @click.stop="clickChangeLang()">
    <div class="bg" :class="{move: change}"><img src="@assets/images/home/lang.svg"></div>
    <p class="lang" :class="{lang1: isHome}">{{$t('home.lang')}}</p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isHome: false,
      change: false,
    }
  },
  created () {
    if (this.storage && this.storage.save_language) {
      let lang = this.storage.save_language
      this.setLang(lang)
    }
    this.getData()
  },
  methods: {
    getData () {
      this.isHome = this.$route.path === '/home'
    },
    clickChangeLang () {  
      let lang = ''
      if (this.language === 'CN') {
        lang = 'EN'
      } else {
        lang = 'CN'
      }
      this.setLang(lang)
      this.change = true
      setTimeout(() => {
        this.change = false
      }, 200);
    },
    setLang (lang) {
      this.$store.commit('set_language', lang)  
      this.$i18n.locale = lang   
      this.saveStorage({key: 'save_language', val: lang})
    },
  },
}
</script>
<style scoped lang="stylus">
.miya-lang
  cursor pointer
  width 36px
  height 36px
  line-height 36px
  text-align center
  color $c2
  position relative
  &:hover
    .bg
      animation rotate .2s ease forwards
  .bg
    position absolute
    width 100%
    height 100%
    z-index 1
  .move
    animation rotate .2s ease forwards
  .lang
    position relative
    z-index 2
    font-size $f16
@keyframes rotate
  0%
    transform rotate(0)
  100%
    transform rotate(180deg)
@media screen and (max-width: 800px) 
  .miya-lang
    width 1.6rem
    height 1.6rem
    line-height 1.6rem
    .bg
      img
        width 100%
    .lang
      font-size 0.7rem
</style>