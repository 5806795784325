<template>
  <div class="miya-layout">
    <operation></operation>
    <div class="miya-layout-content">
      <div v-if="isLogin" class="miya-layout-content-box">
        <miya-chat v-show="actionOperation === 'chat'"></miya-chat>
        <miya-draw v-show="actionOperation === 'draw'"></miya-draw>
      </div>
    </div>    
    <user-info v-if="isLogin"></user-info>
    <el-dialog class="dialogSetPhone" :title="$t('ai.bandPhone')" top="10vh" :visible.sync="dialogSetPhone" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">   
      <div class="pageContent">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <p class="inputName">{{$t('login.phone')}}</p>
          <el-form-item label="" prop="phone" label-width="0">
            <div class="phoneEmail">
              <el-select v-model="prefix" class="prefix" placeholder="">
                <el-option v-for="(item, idx) in prefixList" :key="'prefix'+idx" :label="`${item.name} ${item.value}`" :value="item.value"></el-option>
              </el-select>
              <el-input v-model="ruleForm.phone" :placeholder="$t('login.placeholder3')"></el-input>
            </div>
          </el-form-item>
          <p class="inputName">{{$t('login.phoneCode')}}</p>
          <el-form-item label="" prop="phoneCode" label-width="0">
            <el-input class="phoneCode" v-model="ruleForm.phoneCode" :placeholder="$t('login.placeholder3')"></el-input>
            <p class="sendCode" @click="clickSendCode()">{{$t('login.sendCode')}}</p>
          </el-form-item> 
          <p class="tipString">{{$t('login.codeTip')}}</p>
        </el-form> 
      </div>   
      <div class="dialog-footer">
        <el-button class="cancel" type="info" size="medium" plain @click.stop="dialogSetPhone = false">{{$t('login.cancel')}}</el-button>
        <el-button class="ok" type="primary" size="medium" @click.stop="clickSetPhone()">{{$t('login.btnOK')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import operation from './operation.vue'
import userInfo from './userInfo.vue'
import miyaChat from '../chat/index.vue'
import miyaDraw from '../draw/index.vue'
import {mapGetters, mapActions} from 'vuex'
export default {
  data () {
    return {
      isLogin: false,
      dialogSetPhone: false,
      prefix: '+86',
      prefixList: [
        {name: '', value: '+86'},
        {name: '', value: '+1'},
        {name: '', value: '+852'},
        {name: '', value: '+853'},
        {name: '', value: '+886'},
        {name: '', value: '+81'},
        {name: '', value: '+82'},
        {name: '', value: '+65'},
        {name: '', value: '+60'},
        {name: '', value: '+63'},
        {name: '', value: '+66'},
        {name: '', value: '+91'},
        {name: '', value: '+44'},
        {name: '', value: '+49'},
        {name: '', value: '+1'},
        {name: '', value: '+33'},
        {name: '', value: '+39'},
        {name: '', value: '+61'},
        {name: '', value: '+64'},
        {name: '', value: '+255'},
        {name: '', value: '+233'},
        {name: '', value: '+254'},
        {name: '', value: '+46'},
        {name: '', value: '+20'},
        {name: '', value: '+51'},
        {name: '', value: '+591'},
        {name: '', value: '+57'},
        {name: '', value: '+593'},
        {name: '', value: '+52'},
        {name: '', value: '+84'},
        {name: '', value: '+358'},
        {name: '', value: '+31'},
      ],
      ruleForm: {
        phone: '',
        phoneCode: '',
      },
      rules: {
        phone: [
          {required: true, message: this.$t('login.errorPhone'), trigger: 'blur'}
        ],
        phoneCode: [
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['user', 'isLoginGPT', 'actionOperation'])
  },
  created () {
    this.getUserInfo()
  },
  mounted () { 
   var a= this.$router
   console.log(a)
    this.getData() 
  },
  methods: {
    getData () {
      this.rules.phone[0].message = this.$t('login.errorPhone')
      this.prefixList.map((val, idx) => {
        val.name = this.$t(`login.c${idx+1}`)
      })
    },
    getUserInfo () {        
      this.$get('/Check/GetStatus').then(res => {
        if (res.code === 200) {
          this.isLogin = true
          this.$store.commit('set_sessionId', res.message)
        }
      })
      this.$get(`/Check/GetInfo`).then(res => {
        if (res.code === 200) {
          this.$store.commit('set_user', res.result)
          this.$nextTick(() => {
            if (this.isLoginGPT) {
              this.dialogSetPhone = this.user.phoneNum.indexOf('@') >= 0 ? true : false
              this.$store.commit('set_isLoginGPT', false)
            }            
          })
        }
      })
    },
    clickSendCode () {
      if (!this.ruleForm.phone) {
        this.$message.error(this.$t('login.errorPhone'))
        return
      }
      let data = {
        phone: `${this.prefix} ${this.ruleForm.phone}`,
      }
      this.$get(`/Check/SendPhoneCode`, data).then(res => {
        if (res.code == 200) {
          this.$message.success(this.$t('login.sendSuccess'))
        }
      })
    },
    clickSetPhone () {
      if (!this.ruleForm.phoneCode || this.ruleForm.phoneCode.length < 6) {
        this.$message.error(this.$t('login.errorPhoneCode'))
        return
      }
      this.$refs.ruleForm.validate(v => {
        if (v) {
          let data = {
            phone: `${this.prefix} ${this.ruleForm.phone}`,
            code: this.ruleForm.phoneCode
          }
          this.$get(`/Check/BindPhone`, data).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('login.success'))
              this.dialogSetPhone = false
            }
          })
        }
      })
    },
  },
  beforeDestroy () {
  },
  components: {operation, userInfo, miyaChat, miyaDraw}
}
</script>
<style scoped lang="stylus">
.miya-layout
  width 100%
  min-height 100vh
  display flex
  .miya-layout-content
    width calc(100% - 720px)
  .dialogSetPhone
    .dialog-footer
      display flex
      justify-content center
      margin-top 24px
      .cancel, .ok
        width 96px
        border-radius 50px
        height 36px
      .ok
        background: linear-gradient(132deg, #FFBF10 0%, #FF6F00 100%);
    >>>.el-dialog
      border-radius 20px
      .el-dialog__header
        font-size $f20
        font-weight 600
        padding 20px 0
        border-bottom 1px solid $c5
    >>>.el-dialog__body
      padding 24px 32px 16px 32px
    .pageContent
      border-radius 19px
      background-color $c7
      padding 16px 90px 32px 90px
      position relative
      .inputName
        font-size $f16
        color $c1
        padding 10px 0 10px 0
        font-weight 600
      >>>.el-input__inner
        border-radius 10px
        border 1px solid #D0CFCE
        background-color $c7
      .phoneEmail
        display flex
        .prefix
          width 160px
          margin-right 8px
      .tipString
        font-size $f14
        color $c2
        line-height 18px
        margin-top -16px
        margin-bottom 12px
      .sendCode
        position absolute
        top 0
        right 16px
        color $c8
        cursor pointer
@media screen and (max-width: 1440px)
  .miya-layout
    width 1440px
@media screen and (max-width: 800px)
  .miya-layout
    width 100%
    min-height 70vh
    .miya-layout-content
      width 100%
      margin-top 2.4rem
</style> 