<template>
  <div class="miya-invite-list">
    <el-dialog :visible.sync="dialogInvite" :close-on-click-modal="false" top="6vh" :modal-append-to-body="false" :close-on-press-escape="false" :width="pageWidth < 1100 ? '94%' : '1080px'">
      <div slot="title">
        <p class="title1">{{$t('invite.inviteList')}}</p>
        <p class="subName">{{$t('invite.t2')}}</p>
      </div>
      <div class="inviteCodeBox">
        <p class="name">{{$t('invite.inviteCode')}}</p>
        <div class="inviteCode">
          <span class="code">{{user.inviteCode}}</span>
          <el-tooltip effect="dark" :content="$t('ai.copy')" placement="bottom">
            <span class="copyBtn1" :data-clipboard-text="user.inviteCode" @click="clickCopy()"><i class="iconfont icon-copy"></i></span>
          </el-tooltip>
        </div>
        <img class="img" src="@assets/images/ai/red.svg">
        <p class="text">{{$t('invite.text1')}}</p>
        <p class="text1">{{count}}</p>
        <p class="text">{{$t('invite.text2')}}</p>
        <p class="text2">{{totalMi}}</p>
        <p class="text">{{$t('invite.text3')}}</p>
      </div>
      <div class="table-data">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :width="pageWidth < 1000 ? '308px' : '508px'" max-height="508px" style="width: 100%">
          <el-table-column prop="id" :label="$t('invite.table1')" show-overflow-tooltip min-width="160">
          </el-table-column>
          <el-table-column prop="account" :label="$t('invite.table2')" show-overflow-tooltip min-width="160">
          </el-table-column>
          <el-table-column prop="miyaCoin" :label="$t('invite.table3')" show-overflow-tooltip min-width="160">
          </el-table-column>
          <el-table-column prop="createTime" :label="$t('invite.table4')" show-overflow-tooltip min-width="160">
          </el-table-column>
          <div slot="empty" class="table_nodata">
            <img v-if="!tableLoading" class="nodata" src="@/assets/images/home/noData.png" height="240" alt="" />
            <p v-if="!tableLoading" class="nodataText">{{$t('order.noData')}}</p>
          </div>
        </el-table>
      </div>       
      <div class="pagination">
        <div class="flex1"></div>
        <el-pagination size="medium" :layout="pageWidth < 800 ? 'total,prev, pager, next' : 'total, sizes, prev, pager, next'" :page-sizes="[8,20,30,50]" :page-size="pageSize" :current-page="page" :pager-count="5" :total="total" @size-change='changeDataSize'  @current-change='changePage'></el-pagination>
      </div> 
    </el-dialog>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data () {
    return {
      dialogInvite: false,
      searchText: '',
      tableLoading: false,
      tableData: [],
      activeTab: -1,
      total: 0,
      page: 1,
      pageSize: 8,
      count: 0,
      totalMi: 0,
    }
  },
  computed: {
    ...mapGetters(['user', 'actionOperation', 'historyChat', 'historyDraw'])
  },
  mounted () {  
    this.getData()
  },
  methods: {
    getData () {
    },
    getDataList () {
      this.tableData = []
      this.tableLoading = true
      let params = {
        status: this.activeTab,
        page: this.page,
        limit: this.pageSize
      }
      if (this.searchText) {
        params.key = this.searchText
      }
      this.$get(`/InviteHistory/Load`, params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.tableData = res.data
          this.total = res.count
        }
      })
    },
    getTotal () {
      this.$get(`/InviteHistory/Analysis`).then(res => {
        if (res.code === 200) {
          this.count = res.result.count
          this.totalMi = res.result.totalCoin
        }
      })
    },
    clickCopy () {      
      var clipboard = new this.Clipboard(`.copyBtn1`)
      clipboard.on('success', () => {
        this.$message.success(this.$t('ai.copy1'))
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$message.success(this.$t('ai.copy2'))
        clipboard.destroy()
      })
    },
    changeDataSize (pageSize) {
      this.page = 1
      this.pageSize = pageSize
      this.getDataList()
    },
    changePage (page) {
      this.page = page
      this.getDataList()
    },
    show () {
      this.dialogInvite = true
      this.getDataList()
      this.getTotal()
    },
    hide () {
      this.dialogInvite = false
    }
  },
  components: {}
}
</script>
<style scoped lang="stylus">
.miya-invite-list
  .title1
    color $c1
    font-weight 600
    font-size $f20
  .subName
    font-size $f12
    margin 12px 0 6px 0
    color $c2
  .inviteCodeBox
    display flex
    line-height 40px
    margin 24px 32px
    .name
      font-size $f16
      color $c1
      margin-right 16px
    .inviteCode
      width 312px
      margin-right 32px
      border-radius 6px
      background-color #F0F0F0
      padding 10px 20px
      color $c1
      font-size $f14
      line-height 20px
      display flex
      .code
        flex 1
      .icon-copy
        color $c8
        cursor pointer
        &:hover
          color $c8
    .img
      margin-right 6px
    .text
      font-size $f14
      color $c2
    .text1, .text2
      color $c1
      font-weight 600
      margin 0 4px
      font-size $f16
    .text2
      color #FF3923
  .table-data
    padding 0 32px
    .icon-status
      width 13px
      margin-right 6px
      position relative
      top 2px
  .pagination
    display flex
    padding 16px 24px
    margin-top 8px
    border-top 1px solid $c5
    .flex1
      flex 1
  >>>.el-dialog
    border-radius 20px
    .el-dialog__header
      padding 16px 0 10px 0
      border-bottom 1px solid $c5
  >>>.el-dialog__body
    padding 0
@media screen and (max-width: 800px)
  .miya-invite-list
    .inviteCodeBox
      flex-wrap wrap
      margin 0.5rem
      line-height 1.4rem
      .name
        width 5rem
        margin-right 0
        font-size 0.65rem
      .inviteCode
        width calc(100% - 5rem)
        margin-right 0
        font-size 0.6rem
        padding 0 0.6rem
        line-height 1.4rem
</style> 