<template>
  <div class="miya-chat">
    <div class="headTitle">
      <p class="name">{{ $t("ai.t1") }}</p>
      <div class="configBtn" @click="dialogPrompt = true">
        <i class="iconfont icon-config"></i>
        <span>{{ $t("ai.config") }}</span>
      </div>
    </div>
    <div class="chat-panel" id="chat-panel">
      <div class="list" id="chat-list">
        <div
          class="item"
          :class="{ ai: item.from === 'ai', checkRow: item.isCheck }"
          v-for="(item, idx) in dataList"
          :key="'chat' + idx"
        >
          <el-tooltip effect="dark" :content="$t('ai.tip1')" placement="left">
            <div
              v-if="idx != 0"
              class="checkBox"
              :class="{ check: item.isCheck }"
              @click="clickCheck(item, idx)"
            >
              <i v-if="item.isCheck" class="el-icon-check"></i>
            </div>
          </el-tooltip>
          <div class="avatar" v-if="item.from === 'ai'">
            <img class="aiImg" src="@assets/images/ai/ai.svg" />
          </div>
          <div style="flex: 1" v-if="item.from === 'user'"></div>
          <div class="text-box">
            <div class="text">
              <img
                v-if="item.from === 'ai'"
                class="arrow leftArrow"
                src="@assets/images/ai/left.svg"
              />
              <div class="string" v-if="item.from === 'ai'">
                <v-md-editor v-model="item.text" mode="preview"></v-md-editor>
              </div>
              <p
                class="string stringUser"
                v-if="item.from === 'user'"
                v-html="getChatText(item.text)"
              ></p>
              <img
                v-if="item.from === 'user'"
                class="arrow rightArrow"
                src="@assets/images/ai/right.svg"
              />
            </div>
            <div
              class="copy"
              v-if="item.from === 'ai'"
              :class="'aiText' + idx"
              :data-clipboard-text="item.text"
              @click="clickCopy(idx)"
            >
              <i class="iconfont copyImg icon-copy"></i
              ><span>{{ $t("ai.copy") }}</span>
            </div>
          </div>
          <div class="avatar" v-if="item.from === 'user'">
            <img
              class="userImg"
              :src="require(`@assets/images/avatar/${avatarId}.svg`)"
            />
          </div>
        </div>
        <div class="item ai loading" v-show="showLoading">
          <div class="avatar">
            <img class="aiImg" src="@assets/images/ai/ai.svg" />
          </div>
          <div class="text-box">
            <div class="text">
              <img class="arrow leftArrow" src="@assets/images/ai/left.svg" />
              <p class="string"><span>. . .</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="phoneNew" v-if="pageWidth < 800">
      <el-button
        size="medium"
        type="primary"
        plain
        icon="el-icon-plus"
        @click="clickCreate"
        >{{ $t("ai.new") }}</el-button
      >
    </div>
    <div class="btn-stop" @click="clickStop()">{{ $t("ai.stop") }}</div>
    <user-input
      @send="sendText"
      :disabled="inputDisabled"
      :placeholder="$t('ai.inputPlaceholder')"
      ref="userInput"
    ></user-input>
    <el-dialog
      :title="$t('ai.t1')"
      :visible.sync="dialogPrompt"
      :show-close="false"
      top="10vh"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="pageWidth < 800 ? '94%' : '750px'"
    >
      <el-input
        class="promptInput"
        v-model="prompt"
        :placeholder="$t('ai.promptPlaceholder')"
        type="textarea"
        :rows="6"
        :maxlength="2000"
      ></el-input>
      <div class="dialog-footer">
        <el-button
          class="cancel"
          type="info"
          size="medium"
          plain
          @click.stop="clickCancel()"
          >{{ $t("login.cancel") }}</el-button
        >
        <el-button
          class="ok"
          type="primary"
          size="medium"
          @click.stop="clickSetPrompt()"
          >{{ $t("login.btnOK") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import userInput from "../layout/userInput.vue";
import { mapGetters, mapActions } from "vuex";
import { postGPT } from "@assets/js/http.js";
export default {
  data() {
    return {
      dataList: [],
      showLoading: false,
      isLoading: false,
      isStop: false,
      prompt: "",
      dialogPrompt: false,
    };
  },
  computed: {
    avatarId() {
      if (this.user && this.user.id) {
        return (
          (Number(
            this.user.id.substring(this.user.id.length - 3, this.user.id.length)
          ) %
            13) +
          1
        );
      }
      return "0";
    },
    inputDisabled() {
      if (this.historyChat && !this.showLoading && !this.isLoading) {
        return false;
      }
      return true;
    },
    ...mapGetters([
      "user",
      "actionOperation",
      "sessionId",
      "actionModel",
      "createChat",
      "historyChat",
    ]),
  },
  created() {
    if (!this.storage && !this.storage.historyList) {
      this.saveStorage({ key: "historyList", val: historyList });
    }
  },
  mounted() {
    this.initEvent();
    this.changeHeight();
    this.getDataList();
  },
  methods: {
    clickCreate() {
      // 新建
      let id = new Date().getTime();
      let historyList = JSON.parse(
        JSON.stringify(this.storage.historyList || { chat: [], draw: [] })
      );
      let list = historyList.chat;
      list.push({
        name: this.$t("ai.emptyChat"),
        id: id,
        prompt: "",
        list: [{ from: "ai", text: this.$t("ai.defaultChat"), isCheck: false }],
      });
      this.$store.commit("set_createChat", true);
      this.$store.commit("set_historyChat", id);
      this.saveStorage({ key: "historyList", val: historyList });
    },
    getDataList() {
      let historyList = JSON.parse(
        JSON.stringify(this.storage.historyList || { chat: [], draw: [] })
      );
      let list = historyList.chat;
      let str = JSON.stringify(historyList);
      console.log(str.length);
      if (str.length > 8000) {
        this.$message.warning(this.$t("operation.warn1"));
      }
      if (!this.historyChat) {
        this.dataList = [
          { from: "ai", text: this.$t("ai.defaultChat"), isCheck: false },
        ];
      } else {
        let obj = list.find((val) => {
          return val.id === this.historyChat;
        });
        this.prompt = obj.prompt;
        let arr = obj.list;
        this.dataList = arr;
      }
      setTimeout(() => {
        this.scrollBottom();
      }, 20);
    },
    getChatText(text) {
      if (text.indexOf("\n") >= 0) {
        let arr = text.split("\n");
        return arr.join("<br/>");
      } else {
        return text;
      }
    },
    sendText(text) {
      this.showLoading = true;
      this.dataList.push({ from: "user", text: text, isCheck: false });
      this.$refs.userInput.clear();
      this.scrollBottom();
      let list = [];
      this.dataList.map((val, idx) => {
        if (val.isCheck || idx == this.dataList.length - 1) {
          let obj = {
            sessionId: this.sessionId,
            role: val.from === "ai" ? "assistant" : "user",
            version: this.actionModel,
            message: val.text,
          };
          list.push(obj);
        }
      });
      let data = {
        prompt: this.prompt,
        userMessageRequest: list,
      };
      let url = "";
      if (this.actionModel === "3") {
        // deprecated, just for old version
        url = "/Chat/Claude";
        this.postGPT(url, data);
      } else if (this.actionModel === "4") {
        // deprecated, just for old version
        url = "/Chat/Bard";
        this.postGPT(url, data);
      } else {
        url = "/Chat/Gpt";
        this.postGPT(url, data);
      }
      if (this.historyChat) {
        let historyList = JSON.parse(
          JSON.stringify(this.storage.historyList || { chat: [], draw: [] })
        );
        let list = historyList.chat;
        let obj = list.find((val) => {
          return val.id === this.historyChat;
        });
        if (obj && obj.name === this.$t("ai.emptyChat")) {
          obj.name = text;
        }
        this.saveStorage({ key: "historyList", val: historyList });
      }
    },
    async postGPT(url, data) {
      const control = new AbortController();
      const signal = control.signal;
      let u = window.baseUrl.indexOf("localhost") >= 0 ? "/api/api" : "/api";
      const response = await fetch(u + url, {
        signal,
        method: "POST",
        baseURL: window.baseUrl,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      this.showAIData(response, control);
    },
    async showAIData(response, control) {
      const encode = new TextDecoder("utf-8");
      const reader = response.body.getReader();
      this.showLoading = false;
      this.dataList.push({ from: "ai", text: "", isCheck: false });
      this.isStop = false;
      this.isLoading = true;
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const text = encode.decode(value);
        if (text == "<ERR>") {
          this.$message.error(this.$t("ai.error3"));
          break;
        } else if (text === "limited") {
          this.dataList.splice(this.dataList.length - 1, 1);
          this.$message.error(this.$t("ai.error4"));
          break;
        } else if (text === "miyacoinlimited") {
          this.dataList.splice(this.dataList.length - 1, 1);
          this.$message.error(this.$t("error.e3"));
        } else {
          let str = this.dataList[this.dataList.length - 1].text + text;
          // let str1 = str.split(/[(\r\n)\r\n]+/)
          // let str2 = str1.join('<br/>')
          if (!this.isStop) {
            this.$set(this.dataList[this.dataList.length - 1], "text", str);
          } else {
            control.abort();
          }
          this.scrollBottom();
        }
      }
      this.isStop = false;
      this.isLoading = false;
      this.saveDataList();
      this.scrollBottom();
      this.getUserInfo();
    },
    clickSetPrompt() {
      this.dialogPrompt = false;
      this.saveDataList();
    },
    clickCheck(item, idx) {
      this.$set(this.dataList[idx], "isCheck", !item.isCheck);
      this.saveDataList();
    },
    saveDataList() {
      if (this.historyChat) {
        let historyList = JSON.parse(
          JSON.stringify(this.storage.historyList || { chat: [], draw: [] })
        );
        let list = historyList.chat;
        let obj = list.find((val) => {
          return val.id === this.historyChat;
        });
        obj.prompt = this.prompt;
        if (obj) {
          obj.list = JSON.parse(JSON.stringify(this.dataList));
        }
        this.saveStorage({ key: "historyList", val: historyList });
      }
    },
    scrollBottom() {
      this.$nextTick(() => {
        $("#chat-panel").scrollTop($("#chat-list").innerHeight() + 200);
      });
    },
    clickCancel() {
      this.dialogPrompt = false;
      this.prompt = "";
    },
    clickStop() {
      this.isStop = true;
      this.isLoading = false;
      this.showLoading = false;
    },
    clickCopy(idx) {
      var clipboard = new this.Clipboard(`.aiText${idx}`);
      clipboard.on("success", () => {
        this.$message.success(this.$t("ai.copy1"));
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message.success(this.$t("ai.copy2"));
        clipboard.destroy();
      });
    },
    createNewChat() {
      this.dataList = [{ from: "ai", text: this.$t("ai.defaultChat") }];
      this.$refs.userInput.clear();
      this.showLoading = false;
      this.$store.commit("set_createChat", false);
    },
    initEvent() {
      window.addEventListener("resize", this.changeHeight);
    },
    destroyWindowResize() {
      window.removeEventListener("resize", this.changeHeight);
    },
    getUserInfo() {
      this.$get(`/Check/GetInfo`).then((res) => {
        if (res.code === 200) {
          this.$store.commit("set_user", res.result);
        }
      });
    },
    changeHeight() {
      this.$nextTick(() => {
        let height = window.innerHeight - 214;
        if (this.pageWidth < 800) {
          height =
            window.innerHeight -
            (document.documentElement.clientWidth / 20) * 2.4 -
            144;
          $("#chat-panel").height(height);
          return;
        }
        $("#chat-panel").height(height > 614 ? height : 614);
      });
    },
    ...mapActions(["saveStorage"]),
  },
  watch: {
    createChat() {
      if (this.createChat) {
        this.createNewChat();
      }
    },
    historyChat() {
      this.getDataList();
    },
  },
  beforeDestroy() {
    this.destroyWindowResize();
  },
  components: { userInput },
};
</script>
<style scoped lang="stylus">
.miya-chat
  position relative
  min-height 100vh
  height 100%
  .headTitle
    height 71px
    border-bottom 1px solid $c4
    padding 18px 24px
    line-height 36px
    display flex
    .name
      color $c1
      font-weight 600
      font-size $f20
      flex 1
    .configBtn
      border-radius 50px
      background: linear-gradient(132deg, #FFBF10 0%, #FF6F00 100%)
      color $c7
      padding 0 26px
      cursor pointer
      font-size $f14
      .iconfont
        font-size $f14
        margin-right 6px
  .chat-panel
    background-color #F6F6F6
    overflow-y auto
    position relative
    .list
      padding 24px 0 32px 0
      margin 0 auto
      .item
        padding 12px 10%
        display flex
        justify-content right
        position relative
        .checkBox
          position absolute
          left 30px
          top 16px
          width 18px
          height 18px
          border-radius 50%
          border 1px solid $c4
          cursor pointer
          text-align center
          line-height 18px
          .el-icon-check
            color $c7
            font-weight 600
            font-size 14px
        .check
          border 1px solid $c8
          background-color $c8
        .avatar
          width 64px
          .aiImg
            width 48px
            margin-right 16px
          .userImg
            margin-left 16px
            width 48px
            border-radius 50%
        .text-box
          max-width calc(100% - 128px)
          .text
            position relative
            padding 12px 24px
            background-color $c8
            color $c7
            border-radius 16px 0 16px 16px
            .string
              line-height 26px
              font-size $f15
              max-width 100%
              .v-md-editor
                width 6rem
                max-width 100%
                background-color transparent
              >>>.github-markdown-body
                padding 0
                font-size $f15
            .stringUser
              width auto
            .arrow
              position absolute
              top 0
            .leftArrow
              left -8px
            .rightArrow
              right -8px
          .copy
            display inline-block
            font-size $f14
            color $c3
            line-height 16px
            margin-top 8px
            cursor pointer
            .copyImg
              margin-right 6px
            &:hover
              color $c8
      .checkRow
        background-color #FFFBF5
      .ai
        justify-content left
        .text-box
          .text
            background-color #EBEBEB
            color $c1
            border-radius 0 16px 16px 16px
      .loading
        .text-box
          .text
            width 70px
            .string
              height 22px
              overflow hidden
              animation dot 1s linear infinite
              font-size $f20
              font-weight 600
  .btn-stop
    position absolute
    bottom 163px
    left 50%
    border-radius 50px
    padding 0 16px
    text-align center
    line-height 36px
    color $c7
    background-color #FF3923
    cursor pointer
    box-shadow: 0px 8px 16px 1px rgba(255,57,35,0.24);
    transform translate3d(-50%,0,0)
    z-index 2
    &:hover
      background-color #FF2933
      box-shadow: 0px 8px 16px 1px rgba(255,57,35,0.34);
  .promptInput
    >>>.el-textarea__inner
      border-radius 14px
      padding 14px 24px
  .dialog-footer
    display flex
    justify-content center
    margin-top 24px
    .cancel, .ok
      width 96px
      border-radius 50px
      height 36px
    .ok
      background: linear-gradient(132deg, #FFBF10 0%, #FF6F00 100%);
  >>>.el-dialog
    border-radius 20px
    .el-dialog__header
      font-size $f20
      font-weight 600
      padding 20px
      border-bottom 1px solid $c5
  >>>.el-dialog__body
    padding 24px 32px 16px 32px
@keyframes dot
  0%
    width 0
  20%
    width 10px
  33%
    width 10px
  53%
    width 20px
  66%
    width 20px
  86%
    width 30px
  100%
    width 30px
@media screen and (max-width: 800px)
  .miya-chat
    min-height calc(100vh - 10rem)
    .phoneNew
      position absolute
      right 0.5rem
      bottom 163px
      z-index 2
    .headTitle
      height 0
      padding 0
      border-bottom none
      .name
        display none
      .configBtn
        position absolute
        z-index 2
        top 0.5rem
        right 0.5rem
    .chat-panel
      .list
        .item
          padding 12px 0.5rem
          .text-box
            .text
              .string
                .v-md-editor
                  width 14rem
          .checkBox
            left calc(0.5rem + 40px)
            top 12px
    >>>.el-dialog__body
      padding 1rem 0.5rem
</style>
