<template>
  <div class="miya-user-info" :class="showUserInfo?'show':''">
    <div class="phoneBg" @click="showUserInfo = false">
      <p class="clickClose">{{$t('login.close1')}}</p>
    </div>
    <div class="phoneUserBtn" v-if="pageWidth < 800" @click="showUserInfo = !showUserInfo">
      <img class="avatar" :src="require(`@assets/images/avatar/${this.avatarId}.svg`)"/>
    </div>
    <div class="headTitle">
      <p class="title1">{{$t('ai.my')}}</p>
      <div class="btnHome" @click="$router.push('/home')"><i class="iconfont icon-home"></i>{{$t('home.home')}}</div>
      <miya-lang></miya-lang>
    </div>
    <div class="user-info">
      <i class="el-icon-close" v-if="pageWidth < 800" @click="showUserInfo = false"></i>
      <div class="user-avatar">
        <img class="avatar" :src="require(`@assets/images/avatar/${avatarId}.svg`)"/>
        <img class="vip" v-if="isVip" src="@assets/images/ai/vip.png"/>
      </div>
      <div class="info">
        <div class="account">
          <p class="username">{{account}}</p>
          <el-tooltip effect="dark" :content="$t('login.logout')" placement="bottom">
            <div class="logout" @click="clickLogout()"><i class="iconfont icon-logout"></i></div>
          </el-tooltip>
        </div>
        <div class="residue-box">
          <span class="name">{{$t('ai.balance')}}</span>
          <span class="num">{{miyaCoin}}</span>
          <span class="unit">[{{$t('order.mi')}}]</span>
        </div>
      </div>
    </div>
    <div class="info-btns">
      <div class="btn recharge" @click="clickRecharge()">{{$t('order.btnMi')}}</div>
      <el-tooltip effect="dark" :content="$t('order.title1')" placement="bottom">
        <div class="listIcon" @click="clickOrder()"><i class="iconfont icon-order"></i></div>
      </el-tooltip>
      <el-tooltip effect="dark" :content="$t('order.title2')" placement="bottom">
        <div class="listIcon" @click="clickConsumption()"><i class="iconfont icon-consumption"></i></div>
      </el-tooltip>
    </div>
    <div class="createList">
      <div class="btnTotal">
        <el-button size="medium" type="primary" plain icon="el-icon-plus" @click="clickCreate">{{actionOperation === 'chat' ? $t('ai.newChat') : $t('ai.newDraw')}}</el-button>
        <p class="total">{{$t('ai.new1')}} {{dataList.length}} {{$t('ai.new2')}}</p>
      </div>
      <el-scrollbar style="height:338px;">
        <div class="dataList">
          <div class="item" v-for="(item, idx) in dataList" :key="'history'+idx" :class="{active: currentId === item.id}" @click="clickItem(item)">
            <p class="name" v-if="item.id !== editId">{{item.name}}</p>
            <input class="editName" autofocus :id="'input'+idx" v-model="editName" v-show="item.id === editId" @click.stop="" @blur="blurInput()"/>
            <el-tooltip effect="dark" :content="$t('ai.editName')" placement="bottom">
              <i class="el-icon-edit" @click.stop="clickEditName(item, idx)"></i>
            </el-tooltip>
            <i class="iconfont icon-delete" @click.stop="clickDelete(item)"></i>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="invitePanel">
      <div class="title1">
        <span class="name">{{$t('invite.inviteCode')}}</span>
        <span class="inviteList" @click="clickInviteList">{{$t('invite.inviteList')}} <i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="inviteCode">
        <span class="code">{{user.inviteCode}}</span>
        <el-tooltip effect="dark" :content="$t('ai.copy')" placement="bottom">
          <span class="copyBtn1" :data-clipboard-text="user.inviteCode" @click="clickCopy()"><i class="iconfont icon-copy"></i></span>
        </el-tooltip>
      </div>
      <div class="tips">
        <img class="img" src="@assets/images/ai/red.svg">
        <p class="text">{{$t('invite.text4')}}</p>
        <p class="text text1">2</p>
        <p class="text">{{$t('invite.text5')}}</p>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogDelete" :modal-append-to-body="false" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="246px">
      <div class="deleteWarning"><i class="el-icon-warning"></i></div>
      <div class="deleteTitle">{{$t('order.deleteDialogTitle')}}</div>
      <div class="deleteText">{{$t('order.deleteDialogText')}}</div>      
      <div class="pay-footer">
        <el-button class="cancel" type="info" size="medium" plain @click.stop="dialogDelete = false">{{$t('login.cancel')}}</el-button>
        <el-button class="ok" type="info" size="medium" @click.stop="clickDeleteData()">{{$t('login.btnOK')}}</el-button>
      </div>
    </el-dialog>
    <order ref="order"></order>
    <consumption ref="consumption"></consumption>
    <invitelist ref="invitelist"></invitelist>
    <recharge ref="recharge" :miyaCoin="miyaCoin" :residueDraw="residueDraw"></recharge>
  </div>
</template>
<script>
import {logout} from '@assets/js/http.js'
import recharge from './recharge.vue'
import order from './order.vue'
import consumption from './consumption.vue'
import invitelist from './inviteList.vue'
import miyaLang from '@components/lang.vue'
import {mapGetters, mapActions} from 'vuex'
export default {
  data () {
    return {
      dialogDelete: false,
      deleteData: null,
      editId: null,
      editName: '',
      showUserInfo: false
    }
  },
  computed: {
    account () {
      return this.user.account || ''
    },
    miyaCoin () {
      return this.user.miyaCoin || 0
    },
    residueDraw () {
      return this.user.imageCount || 0
    },
    isVip () {
      return this.user.isVip || false
    },
    avatarId () {
      if (this.user && this.user.id) {
        return Number(this.user.id.substring(this.user.id.length - 3, this.user.id.length)) % 13 + 1
      }
      return '0'
    },
    dataList () {      
      if (this.storage && this.storage.historyList) {
        if (this.actionOperation === 'chat') {
          return this.storage.historyList.chat || []
        } else {
          return this.storage.historyList.draw || []
        }
      } else {
        return []
      }
    },
    currentId () {
      if (this.actionOperation === 'chat') {
        return this.historyChat
      } else {
        return this.historyDraw
      }
    },
    ...mapGetters(['user', 'actionOperation', 'historyChat', 'historyDraw'])
  },
  created () {
  },
  mounted () {  
  },
  methods: {
    clickRecharge () {
      this.hidePhoneCard()
      this.$refs.recharge.show()
    },
    clickInviteList () {
      this.hidePhoneCard()
      this.$refs.invitelist.show()
    },
    clickOrder () {
      this.hidePhoneCard()
      this.$refs.order.show()
    },
    clickConsumption () {
      this.hidePhoneCard()
      this.$refs.consumption.show()
    },
    clickCreate () { // 新建
      this.hidePhoneCard()
      let id = new Date().getTime()
      let historyList = JSON.parse(JSON.stringify(this.storage.historyList || {chat: [], draw: []}))
      if (this.actionOperation === 'chat') { 
        let list = historyList.chat
        list.push({name: this.$t('ai.emptyChat'), id: id, prompt: '', list: [{from: 'ai', text: this.$t('ai.defaultChat'), isCheck: false}]})
        this.$store.commit('set_createChat', true)
        this.$store.commit('set_historyChat', id)
        this.saveStorage({key: 'historyList', val: historyList})
      } else {
        let list = historyList.draw
        list.push({name: this.$t('ai.emptyDraw'), id: id, list: [{from: 'ai', text: this.$t('ai.defaultDraw'), isCheck: false}]})
        this.$store.commit('set_createDraw', true)
        this.$store.commit('set_historyDraw', id)
        this.saveStorage({key: 'historyList', val: historyList})
      }
    },
    clickItem (item) {
      this.hidePhoneCard()
      if (this.actionOperation === 'chat') { 
        this.$store.commit('set_historyChat', item.id)
      } else {
        this.$store.commit('set_historyDraw', item.id)
      }
    },
    clickEditName (item, idx) {
      this.editId = item.id
      this.editName = item.name
      this.$nextTick(() => {
        $(`#input${idx}`).focus()
        $(`#input${idx}`).select()
      })
    },
    blurInput () {
      let historyList = JSON.parse(JSON.stringify(this.storage.historyList || {chat: [], draw: []}))
      let list = []
      if (this.actionOperation === 'chat') {
        list = historyList.chat
      } else {
        list = historyList.draw
      }
      let obj = list.find(val => {return val.id === this.editId})
      if (obj) {
        obj.name = this.editName
        this.saveStorage({key: 'historyList', val: historyList})
      }
      this.editName = ''
      this.editId = null
    },  
    clickDelete (item) { // 删除历史记录
      this.hidePhoneCard()
      this.deleteData = item
      this.dialogDelete = true
    },
    clickDeleteData () {
      let historyList = JSON.parse(JSON.stringify(this.storage.historyList || {chat: [], draw: []}))
      let list = []
      if (this.actionOperation === 'chat') { 
        list = historyList.chat
      } else {
        list = historyList.draw
      }
      let idx = list.findIndex(val => {return val.id ===  this.deleteData.id})
      if (idx >= 0) {
        list.splice(idx, 1)
      }
      this.saveStorage({key: 'historyList', val: historyList})
      this.dialogDelete = false
      if (this.actionOperation === 'chat' && this.deleteData.id === this.historyChat) {
        this.$store.commit('set_historyChat', '')
      } else if (this.actionOperation === 'draw' && this.deleteData.id === this.historyDraw) {
        this.$store.commit('set_historyDraw', '')
      }      
    },
    clickLogout () {
      this.$post(`/Check/Logout`).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          logout()
        }        
      })
    },
    clickCopy () {      
      var clipboard = new this.Clipboard(`.copyBtn1`)
      clipboard.on('success', () => {
        this.$message.success(this.$t('ai.copy1'))
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$message.success(this.$t('ai.copy2'))
        clipboard.destroy()
      })
    },
    hidePhoneCard () {
      if (this.pageWidth < 800) {
        this.showUserInfo = false
      }
    },
    ...mapActions(['saveStorage']),
  },
  components: {recharge, miyaLang, order, invitelist, consumption}
}
</script>
<style scoped lang="stylus">
.miya-user-info
  width 360px
  padding-bottom 144px
  position relative
  border-left 1px solid $c4
  .phoneBg
    display none
  .headTitle
    height 71px
    border-bottom 1px solid $c4
    color $c1
    padding 16px 24px
    display flex 
    justify-content space-between
    .title1
      color $c1
      font-weight 600
      font-size $f20
      line-height 36px
      flex 1
    .btnHome
      padding 0 20px
      color $c2
      font-size $f16
      background-color #F0F0F0
      line-height 36px
      border-radius 50px
      cursor pointer
      margin-right 36px
      .iconfont
        margin-right 6px
        font-size $f16
      &:hover
        color $c1
  .user-info
    display flex
    padding 24px
    .user-avatar
      margin-right 24px
      width 64px
      position relative
      .avatar
        width 64px
        height 64px
        border-radius 50%
      .vip
        position absolute
        top 0
        left 0
    .info
      flex 1
      overflow hidden
      .account
        margin-bottom 8px
        display flex
        line-height 32px
        .username
          flex 1
          color $c1
          font-weight 600
          no-wrap()
        .logout
          color $c2
          width 32px
          height 32px
          border-radius 50%
          background-color $c5
          text-align center
          &:hover
            color $c1
    .residue-box
      margin-top 8px
      color $c2
      font-size $f14
      line-height 32px
      .num
        font-size $f20
        color $c1
        margin-left 16px
        color #FF3923
      .unit
        font-size $f12
        margin-left 6px
  .info-btns
    padding 0 24px 24px 24px
    display flex
    border-bottom 1px solid $c4
    .btn
      border-radius 50px
      height 36px
      font-size $f14
      text-align center
      padding 8px 0
      line-height 18px
      cursor pointer
    .recharge
      margin-right 24px
      color $c7
      width 168px
      background: linear-gradient(88deg, #FFBF10 0%, #FF6F00 100%)
      .vip
        margin-right 4px
        position relative
        top 2px
    .listIcon
      margin-right 16px
      width 36px
      height 36px
      line-height 30px
      text-align center
      font-size 20px
      color $c2
      cursor pointer
      border-radius 50%
      background-color #F0F0F0
      &:hover
        color $c1
    .operation
      flex 1
      background-color #f6f6f6
      line-height 12px
      border 1px solid #f6f6f6
      line-height 16px
      color $c2
      &:hover
        color $c1
        border 1px solid $c4
  .createList
    padding 20px 10px 20px 24px
    height calc(100% - 260px)
    .btnTotal
      display flex
      margin-bottom 15px
      .total
        flex 1
        text-align right
        color #D0CFCE
        line-height 36px
    .dataList
      margin-right 14px
      .item
        margin-bottom 10px
        border-radius 6px
        padding 12px 20px
        background-color #F6F6F6
        display flex
        color $c2
        font-size $f14
        cursor pointer
        line-height 24px
        .name, .editName
          flex 1
          margin-right 12px
          no-wrap()
        .editName
          border none
          font-size $f14
          line-height 24px
          color $c1
          background-color transparent
        .el-icon-edit
          display none
          font-size $f18
          margin 3px 12px 0 0
          color $c1
        &:hover
          color $c1
          .el-icon-edit
            display block
        .icon-delete
          color $c10
      .active
        color $c1
        background-color $c81
  .invitePanel
    padding 24px 24px 32px 24px
    position absolute
    left 0
    width 100%
    bottom 0
    border-top 1px solid $c4
    .title1
      display flex
      line-height 16px
      margin-bottom 6px
      .name
        color $c1
        font-weight 600
        margin-right 8px
        font-size $f14
        flex 1
      .inviteList
        color $c8
        margin-left 8px
        font-size $f14
        cursor pointer
    .inviteCode
      border-radius 6px
      background-color #F0F0F0
      padding 10px 20px
      color $c1
      font-size $f14
      line-height 20px
      display flex
      .code
        flex 1
      .icon-copy
        color $c8
        cursor pointer
        &:hover
          color $c8
    .tips
      display flex
      margin-top 4px
      .img
        margin-right 6px
      .text
        line-height 20px
        font-size $f14
      .text1
        font-weight 600
        color #FF3923
        margin 0 3px
  .pay-footer
    display flex
    .ok, .cancel
      flex 1
      border-radius 50px
    .cancel
      background-color $c7
      &:hover
        background-color $c5
        color $c1
  .deleteWarning
    text-align center
    font-size 56px
    color $c10
  .deleteTitle
    font-size $f16
    color $c10
    text-align center
    margin 24px 0 12px 0
  .deleteText
    font-size $f14
    color $c2
    text-align center
    margin-bottom 32px
@media screen and (max-width: 800px)
  .miya-user-info
    position absolute
    width 30%
    top 0
    right 0
    border-left none
    padding-bottom 0
    .user-info, .info-btns, .createList, .invitePanel, .phoneBg
      display none
    .headTitle
      height 0
      border-bottom none
      padding 0
      .title1, .btnHome
        display none
      >>>.miya-lang
        position relative
        top 0.5rem
        left 1rem
    .phoneUserBtn
      position absolute
      left 3.5rem
      top 0.5rem
      display flex
      justify-content space-between
      cursor pointer
      .avatar
        width 1.6rem
        height 1.6rem
        border-radius 50%
  .show
    z-index 13
    .phoneBg
      position fixed
      top 0
      left 0
      display block
      width 100vw
      height 100vh
      background-color rgba(0,0,0,0.5)
      .clickClose
        font-size 0.5rem
        position absolute
        left 0
        width 100%
        bottom 1rem
        color $c4
        cursor pointer
        text-align center
    .headTitle
      .miya-lang
        display none
    .user-info, .info-btns, .createList, .invitePanel
      display block
      background-color $c7
      width 100vw
      position relative
      left -70vw
    .user-info
      top 0
      padding 1rem
      .el-icon-close
        color $c1
        z-index 15
        position fixed
        cursor pointer
        font-size 1rem
        top 1rem
        right 1rem
      .user-avatar
        margin 0 auto
      .info
        width 220px
        margin 1rem auto 0 auto
    .info-btns
      top -1px
      display flex
      justify-content center
    .createList, .invitePanel
      top -1px


</style> 
<style lang="stylus">
.miya-user-info
  .btn-delete   
    border 1px solid transparent
    background-color $c5
    color $c1
    &:hover
      background-color $c4
      color $c1
  .el-button--primary.is-plain
    border-radius 6px
</style> 