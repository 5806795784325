
<template>
  <div class="miyaGPT-recharge">
    <el-dialog title="" :visible.sync="dialogRecharge" :show-close="false" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" :width="pageWidth < 900 ? '94%' : '900px'" top="6vh">
      <div class="bgCard">
        <img class="bg" src="@assets/images/ai/bgCard.svg">
        <div class="close" @click="dialogRecharge=false"><i class="el-icon-close"></i></div>
        <div class="recharge-user-info">
          <div class="user-avatar">
            <img class="avatar" :src="require(`@assets/images/avatar/${avatarId}.svg`)"/>
            <img class="vip" v-if="isVip" src="@assets/images/ai/vip.png"/>
          </div>
          <div class="info">
            <p class="account">{{account}}</p>
            <div class="residue-box">
              <span class="name">{{$t('ai.balance')}}</span>
              <span class="num"> {{miyaCoin}}</span>
              <span class="unit"> [ {{$t('order.mi')}} ]</span>
            </div>
          </div>
        </div>
        <div class="selectVIP">
          <img class="payBg" src="@assets/images/ai/payBg.png">
          <div class="list">
            <div class="item" v-for="(item, idx) in dataList" :key="'item'+idx" :class="{active: idx === selectId}" @click="selectId=idx">
              <p class="price"><span class="unit">{{$t('ai.symbol')}}</span>{{item.price}}</p>
              <div class="mi">
                <p class="value">{{item.value}}</p>
                <p class="unit">[ {{$t('order.mi')}} ]</p>
              </div>
              <div class="flag" v-if="item.value != item.price">{{$t('ai.preferential')}}</div>
            </div>
          </div>
        </div>
        <div class="discountBox" v-if="miyaCoin>=20">
          <p class="labelName">{{$t('ai.customAmountlabel')}}</p>
          <div class="inputBox">
            <el-input class="discountCode" size="medium" v-model="customAmount" :placeholder="$t('ai.customAmountplaceholder')" @click.native="selectId=-1"></el-input>
          </div>          
        </div>
        <div class="discountBox">
          <p class="labelName">{{$t('ai.discount')}}</p>
          <div class="inputBox">
            <el-input class="discountCode" size="medium" v-model="discountCode"></el-input>
          </div>          
        </div>
        <div class="discountBox">
          <p class="labelName">{{$t('ai.payType')}}</p>
          <div class="payType">
            <div class="item" :class="{active: payType === 'alipay'}" @click.stop="payType = 'alipay'"><img class="icon" src="@assets/images/ai/zfb.png"><span>{{$t('ai.Alipay')}}</span></div>
            <div class="item" :class="{active: payType === 'native'}" @click.stop="payType = 'native'"><img class="icon vx" src="@assets/images/ai/vx.png"><span>{{$t('ai.WeChat')}}</span></div>
            <div class="item" :class="{active: payType === 'adyen'}" @click.stop="payType = 'adyen'"><img class="mc" src="@assets/images/ai/other.svg"></div>
            <!-- <div class="item" :class="{active: payType === 'stripe'}" @click.stop="payType = 'stripe'"><svg viewBox="0 0 60 25" xmlns="http://www.w3.org/2000/svg" width="60" height="25" ><title>Stripe logo</title><path fill="var(--userLogoColor, #ffffff)" d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z" fill-rule="evenodd"></path></svg></div> -->
            
          </div>          
        </div>
        <div class="btns">
          <el-button class="ok" type="info" style="width:160px;" size="medium" @click.stop="clickOK()">{{$t('ai.payNow')}}</el-button>
        </div>
        <div class="recharge-code-box" v-if="showQrCard">
          <div class="qrImg" v-if="loadQr">
            <img class="loading" src="@assets/images/home/load.gif">
          </div>
          <div class="qrImg" v-if="!loadQr && payType !== 'adyen'">
            <img class="qrcode" :src="payQr">
          </div>
          <div class="info-text">
            <p class="priceBox">
              <span class="mi">+ {{mi}} {{$t('order.mi')}}</span>
              <span v-if="price">
                <span>{{$t('order.price')}}</span>
                <span class="price">{{price}}</span>
                <span>[{{$t('order.rmb')}}]</span>
              </span>
            </p>
            <p class="tip" v-if="payType !== 'adyen'">{{$t('ai.payTip')}}</p>
          </div>
          <div class="btns">
            <el-button class="cancel" plain type="info" style="width:100px;" size="medium" @click.stop="clickCancel()">{{$t('login.cancel')}}</el-button>
            <el-button class="ok" type="info" style="width:170px;" size="medium" @click.stop="clickDone()">{{$t('ai.payBtn')}}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogAdyen" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" :width="pageWidth < 900 ? '90%' : '900px'" top="5vh">
      <div class="adyenContent">
        <div class="close" @click="dialogAdyen=false"><i class="el-icon-close"></i></div>
        <div class="dropin-container" id="dropin-container"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import AdyenCheckout from '@adyen/adyen-web';
export default {
  data () {
    return {
      dialogRecharge: false,
      dataList: [
        {name: 'miya20', value: 20, price: 20},
        {name: 'miya50', value: 60, price: 50},
        {name: 'miya100', value: 130, price: 100},
        {name: 'miya200', value: 280, price: 200},
        {name: 'miya500', value: 750, price: 500},
        {name: 'miya1000', value: 1600, price: 1000},
      ],
      list: [],
      selectId: 0,
      discountCode: '',
      customAmount: '',
      showQrCard: false,
      loadQr: true,
      payQr: '',
      price: 0,
      payType: '',
      dialogAdyen: false,
    }
  },
  props: {
    miyaCoin: {
      type: Number,
      default: 0
    },
  },
  computed: {
    account () {
      return this.user.account || ''
    },
    isVip () {
      return this.user.isVip || false
    },
    mi () {
      return this.dataList[this.selectId].value
    },
    avatarId () {
      if (this.user && this.user.id) {
        return Number(this.user.id.substring(this.user.id.length - 3, this.user.id.length)) % 13 + 1
      }
      return '0'
    },
    ...mapGetters(['user', 'actionOperation'])
  },
  created () {
  },
  methods: {
    clickOK () {
      // if (this.payType === 'adyen') {
      //   this.$message.warning(this.$t('ai.commingSoon'))
      //   return
      // }
      if (this.payType) {
        this.showQrCard = true
        this.loadQr = true
        this.price = ''
        let item = {}
        if(this.selectId!=-1){
          this.customAmount=''
          item=this.dataList[this.selectId]
        }else{
          item={"name":"miya"+this.customAmount}
        }
        let data = {
          shopType: item.name,
          paytype: this.payType,
          discountKey: this.discountCode
        }
        this.$get(`/MiyaPayment/Pay`, data).then(res => {
          this.loadQr = false
          if (res.code === 200) {
            if (this.payType === 'adyen') {
              this.getConfiguration(res.result.sessionId, res.result.sessionData)
            }else if(this.payType === 'stripe'){
              window.location.href = res.result.sessionData
            } else {
              this.payQr = res.result.qr
              this.price = res.result.price
            }
          } else {
            this.showQrCard = false
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error(this.$t('ai.errorPayType'))
      }
    },
    clickCancel () {
      this.showQrCard = false
    },
    clickDone () {
      this.showQrCard = false
      this.loadQr = false
      this.close()
      this.getUserInfo()
    },
    getUserInfo () {
      this.$get(`/Check/GetInfo`).then(res => {
        if (res.code === 200) {
          this.$store.commit('set_user', res.result)
        }
      })
    },
    async getConfiguration (sessionid, sessionData) {
      const configuration = {
        environment: 'live', // Change to 'live' for the live environment.
        clientKey: 'live_OX724TTSUZG7ZE5KUZF3EBMXRILZHW73', // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
        analytics: {
          enabled: true // Set to false to not send analytics data to Adyen.
        },
        session: {
          id: sessionid, // Unique identifier for the payment session.
          sessionData: sessionData // The payment session data.
        },
        onPaymentCompleted: (result, component) => {
          console.info(result, component);
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component);
        },
        // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
        // For example, this is 3D Secure configuration for cards:
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: true
          }
        }
      };
      const checkout = await AdyenCheckout(configuration)
      
      this.dialogAdyen = true
      this.$nextTick(() => {
        const dropinComponent = checkout.create('dropin').mount('#dropin-container');
        dropinComponent.update();
      })
    },
    show () {
      this.dialogRecharge = true
    },
    close () {
      this.dialogRecharge = false
    }
  },
  components: {}
}
</script>
<style scoped lang="stylus">
.miyaGPT-recharge
  color $c7
  user-select none
  .bgCard
    box-shadow 0 16px 24px rgba(20,17,14,0.3)
    width 900px
    height 689px
    position relative
    overflow hidden
    border-radius 22px
    .bg
      height: 100%
      position absolute
      top 0
      left 0
    .close
      position absolute
      width 32px
      height 32px
      top 16px
      right 16px
      border-radius 50%
      color $c4
      font-size $f16
      text-align center
      line-height 32px
      cursor pointer
      &:hover
        background-color $c71
    .recharge-user-info, .selectVIP, .discountBox, .btns
      width 820px
      position relative
      z-index 5
    .recharge-user-info
      display flex
      margin 32px 40px 24px 40px
      .user-avatar
        width 64px
        margin-right 24px
        position relative
        .avatar
          width 100%
          border-radius 50%
        .vip
          position absolute
          top 0
          left 0
      .info
        flex 1
        .account
          color $c7
          font-weight 600
          font-size $f16
          margin-bottom 12px
        .vip
          margin-bottom 16px
        .residue-box
          color #D0CFCE
          font-size $f14
          margin-top 6px
          display inline-block
          .num
            margin 0 8px 0 16px
            color #FF3923
            font-size $f20
            font-weight 600
          .unit
            font-size $f12
    .selectVIP
      margin 0 auto
      padding 24px 4px 4px 24px
      .payBg
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        z-index 1
      .list
        position relative
        z-index 6
        display flex
        flex-wrap wrap
        .item
          width calc(33.3% - 20px)
          margin 0 20px 20px 0
          border-radius 12px
          border 1px solid #FFF4BE
          background-color #7D5B36
          padding 12px 20px
          cursor default
          position relative
          display flex
          .price
            flex 1
            color $c8
            font-size $f24
            margin-top 24px
            .unit
              font-size $f16
              margin-right 2px
          .mi
            color #DEC09C
            .value
              margin-bottom 12px
              font-size 28px
            .unit
              font-size $f12
          .flag
            position absolute
            z-index 7
            top -4px
            left -4px
            line-height 24px
            font-size $f14
            color $c7
            border-radius 12px 0 12px 0
            padding 0 22px
            background-color #FF3923
        .active
          overflow hidden
          position relative
          background: linear-gradient(156deg, #634A2E 0%, #14110E 100%);
          border 1px solid $c8
          box-shadow 0 10px 16px rgba(20,17,14, 0.4)
          color $c8
          .price
            color #FFD710
          .mi
            color $c7
    .discountBox
      display flex
      margin 24px auto
      .labelName
        font-size $f14
        color $c7
        margin-right 20px
        line-height 36px
      .inputBox
        flex 1
        >>>.el-input__inner
          border 1px solid #464646
          border-radius 12px
          box-shadow 0 4px 8px #14110E inset
          background-color #322F29
          color $c7
      .payType
        display flex
        .item
          margin-right 24px
          color $c7
          line-height 20px
          padding 10px 16px
          display flex
          cursor pointer
          border-radius 8px
          min-width 100px
          border 1px solid rgba(255,255,255,0)
          .icon
            margin-right 8px
          &:hover
            background-color rgba(255,255,255,0.1)
            border 1px solid rgba(255,255,255,0.12)
        .active
          background-color rgba(255,143,165,0.1)
          border 1px solid $c8
          &:hover
            background-color rgba(255,143,165,0.1)
            border 1px solid $c8
    .btns
      margin 36px auto 0 auto
      text-align center
      .cancel, .ok
        border-radius 50px
      .cancel
        color $c1
        background-color $c4
        border 1px solid $c4
        &:hover
          color $c1
          background-color $c4
      .ok
        background: linear-gradient(88deg, #FFBF10 0%, #FF6F00 100%)
    .recharge-code-box
      z-index 10
      border-radius 12px
      background-color rgba(0,0,0,0.6)
      padding 26px 24px
      position absolute
      height calc(100% - 12px)
      top 6px
      left 6px
      width calc(100% - 12px)
      backdrop-filter blur(5px)
      display flex
      flex-direction column
      justify-content center
      .qrImg
        width 160px
        height 160px
        background-color $c7
        margin 80px auto 36px auto
        img
          width 100%
      .info-text
        text-align center
        .priceBox
          color $c7
          font-size $f14
          margin-bottom 16px
          .mi
            color #FFF4BE
            margin-right 24px
          .price
            font-size 32px
            color $c8
            margin 0 8px
        .tip
          font-size $f14
          color #9C9996
  .adyenContent
    position relative
    .close
      position absolute
      right -30px
      top -30px
      font-size 16px
      color #fff
      border-radius 50%
      padding 6px
      border 1px solid rgba(255,255,255,0.5)
      background-color rgba(255,255,255,0.1)
      cursor pointer
      backdrop-filter blur(5px)
@media screen and (max-width: 800px)
  .miyaGPT-recharge
    .bgCard
      width 18rem
      height auto
      margin 0 auto
      border 2px solid #DEB17E
      background-color #31281C
      .bg
        display none
      .close
        top 0.4rem
        right 0.6rem
        width 0.6rem
        height 0.6rem
        font-size 0.8rem
        &:hover
          background-color transparent
      .recharge-user-info
        width 90%
        margin 0
        padding 1rem
        .user-avatar
          margin-right 0.5rem
      .recharge-code-box
        padding 0.5rem
      .selectVIP
        width calc(100% - 1rem)
        margin 0 0.5rem
        padding 0.5rem 0.1rem  0.1rem 0.5rem
        .list
          .item
            display block
            padding 0.5rem 0.3rem
            width calc(33.3% - 0.5rem)
            margin 0 0.5rem 0.5rem 0
            .price
              text-align center
              margin-top 0.5rem
              font-size 0.7rem
              .unit
                font-size 0.6rem
            .flag
              padding 0 0.7rem
              font-size 0.5rem
              line-height 0.9rem
            .mi
              margin-top 0.5rem
              text-align center
              .value
                font-size 1.2rem
                margin-bottom 0
      .discountBox
        margin 0.5rem
        width calc(100% - 1rem)
        .labelName
          margin-right 0.5rem
        .payType
          flex 1
          flex-wrap wrap
      .btns
        width 100%
        margin 0.5rem auto 0.5rem auto
    .adyenContent
      position relative
      .close
        top -32px
        right -5px
</style>
<style lang="stylus">
.miyaGPT-recharge
  .el-dialog__header
    padding 0
  .el-dialog__body
    padding 0
  .el-dialog
    box-shadow none
    background transparent
</style>
