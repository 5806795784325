<template>
  <div class="miya-consumption-list">
    <el-dialog :title="$t('order.title2')" :visible.sync="dialogOrder" top="6vh" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" :width="pageWidth < 1000 ? '94%' : '980px'">
      <div class="table-data">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData"  :width="pageWidth < 1000 ? '308px' : '508px'" max-height="508px" style="width: 100%">
          <el-table-column prop="id" :label="$t('order.consumptionId')" show-overflow-tooltip min-width="160">
          </el-table-column>
          <el-table-column prop="amount" :label="$t('order.usePrice')" show-overflow-tooltip min-width="160">
          </el-table-column>
          <el-table-column prop="surplus" :label="$t('ai.balance')" show-overflow-tooltip min-width="100">
          </el-table-column>
          <el-table-column prop="useWay" :label="$t('order.useWay')" show-overflow-tooltip min-width="160">
            <template slot-scope="scope">
              <span>{{scope.row.useWay === 'mj' ? $t('home.product2') : $t('home.product1')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" :label="$t('order.useTime')" show-overflow-tooltip min-width="160">
            <template slot-scope="scope">
              <p>{{scope.row.createTime ? scope.row.createTime : '--'}}</p>
            </template>
          </el-table-column>
          <div slot="empty" class="table_nodata">
            <img v-if="!tableLoading" class="nodata" src="@/assets/images/home/noData.png" height="240" alt="" />
            <p v-if="!tableLoading" class="nodataText">{{$t('order.noData')}}</p>
          </div>
        </el-table>
      </div>       
      <div class="pagination">
        <div class="flex1"></div>
        <el-pagination size="medium" :layout="pageWidth < 800 ? 'total,prev, pager, next' : 'total, sizes, prev, pager, next'" :page-sizes="[8,20,30,50]" :page-size="pageSize" :current-page="page" :pager-count="5" :total="total" @size-change='changeDataSize'  @current-change='changePage'></el-pagination>
      </div> 
    </el-dialog>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data () {
    return {
      dialogOrder: false,
      searchText: '',
      tableLoading: false,
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 8,
    }
  },
  mounted () {  
    this.getData()
  },
  methods: {
    getData () {
    },
    getDataList () {
      this.tableData = []
      this.tableLoading = true
      let params = {
        page: this.page,
        limit: this.pageSize
      }
      if (this.searchText) {
        params.key = this.searchText
      }
      this.$get(`/UserMiyaCoinUseLog/load`, params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.tableData = res.data
          this.total = res.count
        }
      })
    },
    changeDataSize (pageSize) {
      this.page = 1
      this.pageSize = pageSize
      this.getDataList()
    },
    changePage (page) {
      this.page = page
      this.getDataList()
    },
    show () {
      this.dialogOrder = true
      this.getDataList()
    },
    hide () {
      this.dialogOrder = false
    }
  },
  components: {}
}
</script>
<style scoped lang="stylus">
.miya-consumption-list
  .totalBox
    display flex
    padding 24px
    .tab
      margin-right 20px
      border-radius 50px
      line-height 36px
      background-color $c6
      text-align center
      font-size $f14
      color $c1
      width 140px
      cursor pointer
    .active
      background-color #FFF4E6
      color $c8
      font-weight 600
    .search-input
      >>>.el-input__inner
        border none
        width 280px
        border-radius 50px
      >>>.el-input__prefix
        color $c1
  .table-data
    padding 24px
    .icon-status
      width 13px
      margin-right 6px
      position relative
      top 2px
  .pagination
    display flex
    padding 16px 24px
    margin-top 8px
    border-top 1px solid $c5
    .flex1
      flex 1
  >>>.el-dialog
    border-radius 20px
    .el-dialog__header
      font-size $f20
      font-weight 600
      padding 20px 0
      border-bottom 1px solid $c5
  >>>.el-dialog__body
    padding 0
</style> 