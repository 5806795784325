<template>
  <div class="miya-user-input">
    <el-input ref="inputAI" class="miyaInput" :disabled="disabled" v-model="text" :placeholder="placeholder" type="textarea" :maxlength="2000"  @keyup.enter.native="enter($event)"></el-input>
    <div class="infoBtn-box">
      <p class="tip">{{$t('ai.wordCount')}}<span class="current">{{text.length}}</span> <span class="all">/ 2000</span></p>
      <el-button :disabled="disabled" type="primary" size="medium" class="btn-send" @click="clickSend()">{{$t('ai.send')}}</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      text: ''
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  mounted () {
  },
  methods: {
    clear () {
      this.text = ''
    },
    enter (e) {
      if (e.shiftKey) {
      } else {
        let ele = document.activeElement
        let pos = ele.selectionStart
        let str = this.text.substr(pos - 1, 1)
        if (str === '\n') {
          let s1 = this.text.substr(0, pos - 1) + this.text.substr(pos, this.text.length)
          this.text = s1
        }
        this.clickSend()
      }
    },
    clickSend () {
      this.$emit('send', this.text)
    }
  },
  components: {}
}
</script>
<style scoped lang="stylus">
.miya-user-input
  position relative
  height 143px
  .miyaInput
    width 100%
    height calc(100% - 48px)
  .infoBtn-box
    display flex
    padding 0 24px 12px 24px
    .tip
      flex 1
      text-align right 
      margin-right 20px
      padding-top 20px
      color $c3
      font-size $f12
      .current, .all
        font-size $f14
      .current
        color $c1
      .all
        color $c2
    .btn-send
      border-radius 50px
      background: linear-gradient(88deg, #FFBF10 0%, #FF6F00 100%);
</style>
<style lang="stylus">
.miya-user-input
  .miyaInput
    .el-textarea__inner
      padding 16px 24px 10px 24px
      border none
      background-color $c7
      height 100%
      resize none
</style>