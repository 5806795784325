<template>
  <div class="miya-order-list">
    <el-dialog
      :title="$t('order.title1')"
      :visible.sync="dialogOrder"
      top="6vh"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="pageWidth < 1000 ? '94%' : '980px'"
    >
      <div class="totalBox">
        <div
          class="tab"
          v-for="(item, idx) in tabList"
          :key="'tab' + idx"
          :class="{ active: activeTab === item.value }"
          @click="clickTab(item)"
        >
          {{ item.name }}({{ item.total }})
        </div>
        <div class="search-input">
          <el-input
            size="medium"
            v-model="searchText"
            :placeholder="$t('order.placeholderSearch1')"
            clearable
            @change="searchList()"
            ><i slot="prefix" class="el-input__icon el-icon-search"></i
          ></el-input>
        </div>
      </div>
      <div class="table-data">
        <el-table
          ref="multipleTable"
          v-loading="tableLoading"
          :data="tableData"
          :width="pageWidth < 1000 ? '308px' : '508px'"
          max-height="508px"
          style="width: 100%"
        >
          <el-table-column
            prop="orderId"
            :label="$t('order.orderId')"
            show-overflow-tooltip
            min-width="160"
          >
          </el-table-column>
          <el-table-column
            prop="shopName"
            :label="$t('order.orderName')"
            show-overflow-tooltip
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="price"
            :label="$t('order.price')"
            show-overflow-tooltip
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="shopType"
            :label="$t('order.mi')"
            show-overflow-tooltip
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            :label="$t('order.orderStatus')"
            show-overflow-tooltip
            width="100"
          >
            <template slot-scope="scope">
              <img
                class="icon-status"
                v-if="scope.row.status === 0"
                src="@assets/images/ai/history2.svg"
              />
              <img
                class="icon-status"
                v-if="scope.row.status === 1"
                src="@assets/images/ai/history3.svg"
              />
              <span>{{
                scope.row.status === 0 ? $t("order.noPay") : $t("order.pay")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('order.createTime')"
            show-overflow-tooltip
            width="160"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.createTime ? scope.row.createTime : "--" }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="payTime"
            :label="$t('order.payTime')"
            show-overflow-tooltip
            width="160"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.payTime ? scope.row.payTime : "--" }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="options"
            :label="$t('order.options')"
            show-overflow-tooltip
            width="160"
          >
            <template>
              <el-button @click="dialogOrderDetail = !dialogOrderDetail&&account=='admin'"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
          <div slot="empty" class="table_nodata">
            <img
              v-if="!tableLoading"
              class="nodata"
              src="@/assets/images/home/noData.png"
              height="240"
              alt=""
            />
            <p v-if="!tableLoading" class="nodataText">
              {{ $t("order.noData") }}
            </p>
          </div>
        </el-table>
      </div>
      <div class="pagination">
        <div class="flex1"></div>
        <el-pagination
          size="medium"
          :layout="
            pageWidth < 800
              ? 'total,prev, pager, next'
              : 'total, sizes, prev, pager, next'
          "
          :page-sizes="[8, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="page"
          :pager-count="5"
          :total="total"
          @size-change="changeDataSize"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog
      title="Order Detail"
      :visible.sync="dialogOrderDetail"
      top="6vh"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="pageWidth < 1000 ? '94%' : '980px'"
    >
      <div class="table-data" style="margin-top: 20px; height: 643px">
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">OrderNo</div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">
              PayType
            </div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              d17f0f54-c55a-4822-8746-bf93cfe82345
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">ADYEN</div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">
              Customer
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">
              Customer Email
            </div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple">Rodolfo Agemy</div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              668915658@hotmail.com
            </div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">
              Product Name
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">
              Unit
            </div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple">MiyaGPT</div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">750M值</div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">
              Unit Price
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">Total Price</div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple">$0.67</div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">$500.00</div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">
              Order Time
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple grid-title">
              Pay Time
            </div></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              2023-10-17 16:59:01
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              2023-10-17 17:00:00
            </div></el-col
          >
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialogOrderDetail: false,
      dialogOrder: false,
      tabList: [
        { name: "", value: -1, total: 0 },
        { name: "", value: 0, total: 0 },
        { name: "", value: 1, total: 0 },
      ],
      searchText: "",
      tableLoading: false,
      tableData: [],
      activeTab: -1,
      total: 0,
      page: 1,
      pageSize: 8,
    };
  },
  computed: {
    account() {
      return this.user.account || "";
    },
  ...mapGetters(["user"])
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.tabList[0].name = this.$t("order.all");
      this.tabList[1].name = this.$t("order.noPay");
      this.tabList[2].name = this.$t("order.pay");
    },
    getTotal() {
      this.$get(`/OrderList/OrderAnlays`).then((res) => {
        if (res.code === 200) {
          this.tabList[0].total = res.result.all;
          this.tabList[1].total = res.result.unPaid;
          this.tabList[2].total = res.result.paid;
        }
      });
    },
    getDataList() {
      this.tableData = [];
      this.tableLoading = true;
      let params = {
        status: this.activeTab,
        page: this.page,
        limit: this.pageSize,
      };
      if (this.searchText) {
        params.key = this.searchText;
      }
      this.$get(`/OrderList/Load`, params).then((res) => {
        this.tableLoading = false;
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.count;
        }
      });
    },
    clickTab(item) {
      this.activeTab = item.value;
      this.page = 1;
      this.pageSize = 8;
      this.getDataList();
    },
    searchList() {
      this.page = 1;
      this.pageSize = 8;
      this.getDataList();
    },
    changeDataSize(pageSize) {
      this.page = 1;
      this.pageSize = pageSize;
      this.getDataList();
    },
    changePage(page) {
      this.page = page;
      this.getDataList();
    },
    show() {
      this.dialogOrder = true;
      this.getDataList();
      this.getTotal();
    },
    hide() {
      this.dialogOrder = false;
    },
  },
  components: {},
};
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  /* background: #d3dce6; */
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  text-align: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.grid-title {
  font-weight: bold;
  font-size: 18px;
}
</style>
<style scoped lang="stylus">
.miya-order-list {
  .totalBox {
    display: flex;
    padding: 24px;

    .tab {
      margin-right: 20px;
      border-radius: 50px;
      line-height: 36px;
      background-color: $c6;
      text-align: center;
      font-size: $f14;
      color: $c1;
      width: 140px;
      cursor: pointer;
    }

    .active {
      background-color: #FFF4E6;
      color: $c8;
      font-weight: 600;
    }

    .search-input {
      >>>.el-input__inner {
        border: none;
        width: 280px;
        border-radius: 50px;
      }

      >>>.el-input__prefix {
        color: $c1;
      }
    }
  }

  .table-data {
    padding: 0 24px;

    .icon-status {
      width: 13px;
      margin-right: 6px;
      position: relative;
      top: 2px;
    }
  }

  .pagination {
    display: flex;
    padding: 16px 24px;
    margin-top: 8px;
    border-top: 1px solid $c5;

    .flex1 {
      flex: 1;
    }
  }

  >>>.el-dialog {
    border-radius: 20px;

    .el-dialog__header {
      font-size: $f20;
      font-weight: 600;
      padding: 20px 0;
      border-bottom: 1px solid $c5;
    }
  }

  >>>.el-dialog__body {
    padding: 0;
  }
}

@media screen and (max-width: 800px) {
  .miya-order-list {
    .totalBox {
      flex-wrap: wrap;

      .tab {
        width: auto;
        padding: 0 1rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style> 