<template>
  <div class="miya-fx" v-show="isShow" :class="{cn: language === 'CN', en: language === 'EN', show: dialogFx, hide: !dialogFx}">
    <i class="el-icon-close" @click="close()"></i>
    <div class="title1">{{$t('ai.fxTitle')}}</div>
    <div class="text1"><label>{{$t('ai.fx1')}}</label><span>{{$t('ai.fx11')}}</span></div>
    <div class="text1"><label>{{$t('ai.fx2')}}</label><span>{{$t('ai.fx21')}}</span></div>
    <div class="text1"><label>{{$t('ai.fx3')}}</label><span>{{$t('ai.fx31')}}</span></div>
    <div class="text1"><label>{{$t('ai.fx4')}}</label><span>{{$t('ai.fx41')}}</span></div>
    <div class="text1"><label>{{$t('ai.fx5')}}</label><span>{{$t('ai.fx51')}}</span></div>
    <div class="text1 text2"><label>{{$t('ai.fx6')}}</label></div>
    <div class="case">{{$t('ai.fx61')}}</div>
    <p class="tip">{{$t('ai.fxTip')}}</p>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data () {
    return {
      dialogFx: false,
      isShow: false,
    }
  },
  computed: {
    ...mapGetters(['language', 'storage'])
  },
  methods: {
    show () {
      this.dialogFx = true
      this.isShow = true
    },
    close () {
      this.dialogFx = false
      setTimeout(() => {
        this.isShow = false
      }, 320);
    },
    toggle () {
      if (this.dialogFx) {
        this.close()
      } else {
        this.show()
      }
    },
  }
}
</script>
<style scoped lang="stylus">
.miya-fx
  position absolute
  top 60px
  right 24px
  border-radius 12px
  box-shadow 0 16px 30px rgba(0,0,0,0.16)
  background-color $c7
  z-index 100
  overflow hidden
  .el-icon-close
    position absolute
    top 16px
    right 20px
    font-weight 600
    font-size $f20
    cursor pointer
  .title1
    text-align center
    color $c1
    font-size $f16
    font-weight 600
    line-height 24px
    padding 16px 0
    border-bottom 1px solid #EBEBEB
    margin-bottom 20px
  .text1
    padding 0 24px
    margin-bottom 14px
    line-height 22px
    font-size $f14
    label
      color $c1
      font-weight 600
    span
      color $c2
  .text2
    margin-bottom 10px
  .case
    margin 0 24px
    border-radius 12px
    padding 16px 20px
    font-size $f14
    line-height 20px
    background-color #F0F0F0
  .tip
    font-size $f12
    color #FF3923
    margin 6px 24px 12px 24px
    line-height 20px
.en
  width 780px
  height 460px
  transform-origin top right
.cn
  width 720px
  height 384px
  transform-origin top right
.show
  animation show .3s ease forwards
.hide
  animation hide .3s ease forwards

@keyframes show
  0%
    transform scale(0)
    border none
  100%
    transform scale(1)
    border 1px solid #D0CFCE
@keyframes hide
  0%
    transform scale(1)
    border 1px solid #D0CFCE
  100%
    transform scale(0)
    border none

@media screen and (max-width: 800px)
  .miya-fx
    right 0.5rem
    .title1
      padding 10px 0
      margin-bottom 12px
    .text1
      line-height 20px
      padding 0 14px
      margin-bottom 12px
    .case
      padding 10px 12px
      margin 0 12px
  .cn, .en
    width calc(100% - 1rem)
    height auto

  
</style>